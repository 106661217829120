export const SKILLS = [
    {
        title: 'frontend',
        icon:"../assets/75.png",
        skills: [
            {skill: "HTML", percentage: '80%'},
            {skill: "CSS", percentage: '70%'},
            {skill: "JavaScript", percentage: '100%'},
            {skill: "Reactjs", percentage: '50%'},
        ],
    },

    {
        title: 'backend',
        icon:"./assets/75.png",
        skills: [
            {skill: "ExpressJs", percentage: '70%'},
            {skill: "Firebase", percentage: '100%'},
        ],
    },

    {
        title: 'Tools',
        icon:"../assets/75.png",
        skills: [
            {skill: "Git", percentage: '80%'},
            {skill: "VsCode", percentage: '70%'},
            {skill: "Responsive Design", percentage: '100%'},
            {skill: "Vercel", percentage: '50%'},
        ],
    },

    {
        title: 'Soft Skills',
        icon:"../assets/75.png",
        skills: [
            {skill: "Problem Solving", percentage: '80%'},
            {skill: "Collaboration", percentage: '70%'},
            {skill: "Attention to Detail", percentage: '100%'},
        ],
    },
];


export const WORK_EXPERIENCE = [
    {
        title: "Debates",
        desc: "Members suggest and debate on a variety of topics, ensuring every session is dynamic and engaging.",
        icon: './assets/bullet1.png' // Replace with path to your debates icon
    },
    {
        title: "Event Preparation",
        desc: "In the lead-up to our signature event, The Circle, we split tasks, organize teams, and work together to make the event a success.",
        icon: './assets/activity2.png' // Replace with path to your event preparation icon
    },
    {
        title: "Feedback sessions",
        desc: "After each event, we hold feedback sessions to reflect and improve on our performance.",
        icon: './assets/activity3.png' // Replace with path to your workshops and feedback icon
    },
    {
        title: "Outdoor Sessions",
        desc: "Our 'Walk and Talk' sessions provide a more relaxed setting for discussions, allowing members to debate while enjoying the outdoors.",
        icon: './assets/activity4.png' // Replace with path to your outdoor sessions icon
    },
    {
        title: "Speech Practice",
        desc: "Some weeks are dedicated to speech practice, helping members refine their speaking skills and styles.",
        icon: './assets/activity5.png' // Replace with path to your speech practice icon
    }
]